import {IActionDto, IBoolResponse, ISystemActions, KnoxApiUrls} from "@aex/security/shared";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AuthType, ParamMetaData, proxyServerUrl} from "@aex/shared/common-lib";
import {HttpClient} from "@angular/common/http";
import {BaseConfigService } from "@aex/shared/root-services";

@Injectable({providedIn:'root'})
export class RegisterKnoxActionsService {
  constructor(
    private readonly http: HttpClient,
    private readonly configService: BaseConfigService,
  ) {}

  public registerKnoxActions(systemName : string, actions : IActionDto[]): Observable<IBoolResponse>  {
    // Any actions to register?
    if (actions.length === 0)
      // Return true
      return new Observable<IBoolResponse>(observer => {
        observer.next({
          success: true,
        });
        observer.complete();
      });


    const systemActions : ISystemActions = {
      system_name: systemName,
      actions : actions,
    };

	  let headerID = this.configService.operatorId ?? this.configService.operator.toUpperCase();

	  console.log('doKnoxLogin.headerID', headerID)

		// Use proxy server to register client actions
	  return this.http.post<IBoolResponse>(
		    proxyServerUrl(`${KnoxApiUrls.SystemActions}/register-client-actions`),
			  systemActions,
			  { params: new ParamMetaData({ handleError: 'knox', authToken: AuthType.NONE,}, ) },
	  );
    // return this.knoxApiService.registerKnoxActions(systemActions);

  }
}
